<template>
  <a-comment>
    <template #actions v-if="$scopedSlots.actions">
      <slot name="actions">
        <a @click="$listeners.reply" href="javascript:;">Reply To</a>
      </slot>
    </template>
    <template #author v-if="author || $scopedSlots.author">
      <slot name="author">{{author}}</slot>
    </template>
    <template #avatar v-if="avatarSrc || $scopedSlots.avatar">
      <slot name="avatar">
        <m-avatar :src="avatarSrc" />
      </slot>
    </template>
    <template #datetime v-if="datetime || $scopedSlots.datetime">
      <slot name="datetime">{{datetime}}</slot>
    </template>
    <template #content>
      <slot name="content" />
    </template>
    <slot />
  </a-comment>
</template>

<script>
import Comment from 'ant-design-vue/es/comment';
import 'ant-design-vue/es/comment/style';

export default {
  name: 'MComment',
  components: { AComment: Comment },
  props: {
    avatarSrc: {
      type: String
    },
    author: {
      type: String
    },
    datetime: {
      type: String
    }
  }
};
</script>
