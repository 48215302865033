<template>
  <a-card-grid>
    <slot/>
  </a-card-grid>
</template>

<script>
import Grid from 'ant-design-vue/es/card/Grid';

export default {
  name: 'MCardGrid',
  __ANT_CARD_GRID: true,
  components: { ACardGrid: Grid }
};
</script>
