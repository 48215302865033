<template>
  <a-select-option-group v-bind="$attrs">
    <template #label>
      <slot name="label"/>
    </template>
    <slot/>
  </a-select-option-group>
</template>

<script>
import Select from 'ant-design-vue/es/select';

export default {
  name: 'MSelectOptGroup',
  components: { ASelectOptionGroup: Select.OptGroup },
  isSelectOptGroup: true
};
</script>
