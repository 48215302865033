<template>
  <component
    :is="loaderType"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
    :speed="1"
    preserveAspectRatio="xMidYMin meet"
    v-bind="$attrs"
  >
    <slot>
      <rect
        :key="line"
        :y="line * 25"
        height="10"
        ry="5"
        v-for="line in currentLines"
        width="98%"
        x="5"
      />
    </slot>
  </component>
</template>

<script>
import {
  ContentLoader,
  ListLoader,
  BulletListLoader
} from 'vue-content-loader/dist/vue-content-loader.es'

export default {
  name: 'MPlaceholder',
  props: {
    type: { type: String, default: undefined },
    lines: { type: Number, default: 20 },
    secondaryColor: { type: String, default: '#dee5ed' },
    primaryColor: { type: String, default: '#eef2f6' }
  },
  computed: {
    loaderType() {
      if (this.type === 'list') {
        return ListLoader
      }
      if (this.type === 'bullets') {
        return BulletListLoader
      }
      return ContentLoader
    },
    currentLines() {
      return Array.from({ length: this.lines }, (v, i) => i)
    }
  }
}
</script>
