<template>
  <a-alert
    :banner="banner"
    :closable="closable"
    :showIcon="showIcon"
    :type="type"
    v-on="$listeners"
  >
    <template #icon v-if="showIcon">
      <slot name="icon">
        <m-icon name="check" v-if="type == 'success'" />
        <m-icon name="times" v-else-if="type == 'error'" />
        <m-icon name="exclamation-circle" v-else-if="type == 'warning'" />
        <m-icon name="info" v-else-if="type == 'info'" />
      </slot>
    </template>
    <template
      #message
      v-if="banner ? $scopedSlots.default : $scopedSlots.message"
    >
      <slot :name="banner ? 'default' : 'message'">
        <h4>Sample Message</h4>
      </slot>
    </template>
    <template #description v-if="!banner">
      <slot :name="banner ? 'default' : 'description'">
        <p>Some description about the message</p>
      </slot>
    </template>
    <template #closeText v-if="closable">
      <slot name="close-btn">
        <m-icon class="text-regular opacity-50" name="times" />
      </slot>
    </template>
  </a-alert>
</template>

<script>
import Alert from 'ant-design-vue/es/alert'
import 'ant-design-vue/es/alert/style'
export default {
  name: 'MAlert',
  components: { AAlert: Alert },
  props: {
    type: {
      type: String,
      default: 'success'
    },
    banner: {
      type: Boolean
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: true
    }
  }
}
</script>
