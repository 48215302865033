import MIcon from './Icon.vue';
import {
  library
} from '@fortawesome/fontawesome-svg-core';
// import { fas } from '@fortawesome/free-solid-svg-icons';

// library.add(fas);

const MIconLibrary = library;

export {
  MIcon,
  MIconLibrary
};
