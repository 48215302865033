<template>
  <a-upload
    :accept="accept"
    :action="action"
    :beforeUpload="beforeUpload"
    :customRequest="customRequest"
    :data="data"
    :defaultFileList="defaultFileList"
    :directory="directory"
    :disabled="disabled"
    :headers="headers"
    :listType="listType"
    :multiple="multiple"
    :name="name"
    :openFileDialogOnClick="openFileDialogOnClick"
    :remove="handleRemove"
    :showUploadList="showUploadList"
    :withCredentials="withCredentials"
    @change="handleChange"
    @preview="handlePreview"
    v-bind="attrs"
  >
    <slot />
  </a-upload>
</template>

<script>
import Upload from 'ant-design-vue/es/upload'
import 'ant-design-vue/es/upload/style'

export default {
  name: 'MUpload',
  components: { AUpload: Upload },
  props: {
    accept: {
      type: String
    },
    action: {
      type: [String, Function]
    },
    directory: {
      type: Boolean
    },
    beforeUpload: {
      type: Function
    },
    customRequest: {
      type: Function
    },
    data: {
      type: [Object, Function]
    },
    defaultFileList: {
      type: Array
    },
    disabled: {
      type: Boolean
    },
    headers: {
      type: Object
    },
    listType: {
      type: String,
      default: 'text'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
    showUploadList: {
      type: [Boolean, Object],
      default: true
    },
    withCredentials: {
      type: Boolean
    },
    openFileDialogOnClick: {
      type: Boolean,
      default: true
    },
    remove: {
      type: Function
    },
    fileList: {
      type: Array
    },
    confirmBeforeRemove: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    attrs() {
      const attrs = {}
      if ((this.fileList || []).length) {
        attrs.fileList = this.fileList
      }
      return attrs
    }
  },
  methods: {
    handleChange(result) {
      this.$emit('change', result)
    },
    handlePreview(file) {
      this.$emit('preview', file)
    },
    handleRemove(file) {
      if (this.confirmBeforeRemove) {
        return this.$confirm({ message: 'You want to remove file?' }).then(() =>
          this.$emit('remove', file)
        )
      }
      this.$emit('remove', file)
      return true
    }
  }
}
</script>
