<template>
  <a-popconfirm
    :cancelText="cancelText"
    :destroyTooltipOnHide="true"
    :okText="okText"
    :okType="okType"
    :placement="placement"
    :getPopupContainer="getContainer"
    ref="popConfirm"
    overlay-class-name="confirm-btn-overlay"
    @cancel="handleCancel"
    @confirm="handleConfirm"
    @visibleChange="handleVisibleChange"
    :overlay-style="overlayStyle"
  >
    <template slot="icon">
      <slot name="icon">
        <m-icon class="pull-left text-warning" name="exclamation-circle" />
      </slot>
    </template>
    <template slot="title">
      <slot name="title">
        <p>Are you sure?</p>
      </slot>
    </template>
    <slot>
      <m-button variant="error">Delete</m-button>
    </slot>
  </a-popconfirm>
</template>

<script>
import Popconfirm from 'ant-design-vue/es/popconfirm'
import 'ant-design-vue/es/popconfirm/style'

export default {
  name: 'MConfirmBtn',
  components: { APopconfirm: Popconfirm },
  props: {
    placement: {
      type: String,
      default: 'rightBottom'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    okText: {
      type: String,
      default: 'Yes'
    },
    okType: {
      type: String,
      default: 'danger'
    },
    overlayStyle: {
      type: Object
    },
    getPopupContainer: {
      type: Function
    }
  },
  created() {
    const handler = this.hidePopconfirm.bind(this)
    let panels = document.body.querySelectorAll('.__panel')
    for (let i = 0; i < panels.length; i++) {
      panels[i].addEventListener('scroll', handler)
    }
    this.$once('hook:beforeDestroy', function() {
      for (let i = 0; i < panels.length; i++) {
        panels[i].removeEventListener('scroll', handler)
      }
      panels = null
    })
  },
  methods: {
    hidePopconfirm() {
      if (this.$refs.popConfirm.sVisible) {
        this.$refs.popConfirm.setVisible(false)
      }
    },
    getContainer() {
      const element = this.getPopupContainer ? this.getPopupContainer() : null
      if (element) {
        return element
      }
      return document.body
    },
    handleVisibleChange(value) {
      if (value) {
        this.$emit('show')
      } else {
        this.$emit('hide')
      }
    },
    handleConfirm(e) {
      this.$emit('confirm')
    },
    handleCancel() {
      this.$emit('cancel')
    }
  }
}
</script>
