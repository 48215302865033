<template>
  <a-rate
    :allowClear="allowClear"
    :allowHalf="allowHalf"
    :autoFocus="autoFocus"
    :count="count"
    :defaultValue="defaultValue"
    :disabled="disabled"
    @change="handleChange"
    v-bind="attrs"
  >
    <template #character v-if="$scopedSlots.default">
      <slot/>
    </template>
  </a-rate>
</template>

<script>
import Rate from 'ant-design-vue/es/rate';
import 'ant-design-vue/es/rate/style';

export default {
  name: 'MRate',
  components: { ARate: Rate },
  model: {
    event: 'change'
  },
  props: {
    allowClear: {
      type: Boolean,
      default: true
    },
    allowHalf: {
      type: Boolean,
      default: true
    },
    autoFocus: {
      type: Boolean
    },
    count: {
      type: Number
    },
    defaultValue: {
      type: Number
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return { innerValue: undefined };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.innerValue = newValue;
      }
    }
  },
  computed: {
    attrs() {
      const { value, ...attrs } = this.$attrs;
      if (this.innerValue || value) {
        attrs.value = this.innerValue || value;
      }
      return attrs;
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>
