<template>
  <a-avatar :alt="alt" :shape="shape" :size="size" v-bind="additionalProps">
    <slot/>
  </a-avatar>
</template>

<script>
import Avatar from 'ant-design-vue/es/avatar';
import 'ant-design-vue/es/avatar/style';

export default {
  name: 'MAvatar',
  components: { AAvatar: Avatar },
  props: {
    icon: {
      type: String
    },
    shape: {
      type: String,
      default: 'circle'
    },
    size: {
      type: [Number, String],
      default: 50
    },
    src: {
      type: String
    },
    alt: {
      type: String,
      default: 'Avatar'
    }
  },
  computed: {
    additionalProps() {
      const p = {};
      if (this.icon) {
        p.icon = this.icon;
      }
      if (this.src) {
        p.src = this.src;
      }
      return p;
    }
  }
};
</script>
