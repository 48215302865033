<template>
  <a-dropdown
    :getPopupContainer="getContainer"
    :placement="placement"
    :trigger="trigger"
    :visible="isOpen"
    @visibleChange="visibleChange"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot :hide="hide" :show="show" :toggle="toggle" name="trigger">
      <m-button :variant="variant">
        {{ triggerText }}
        <m-icon name="chevron-down" size="sm" transform="shrink-2 right-4" />
      </m-button>
    </slot>
    <template #overlay>
      <slot name="menu">
        <m-menu :selectedKeys="selectedKeys">
          <m-menu-item
            class="non-selectable"
            v-if="$scopedSlots['before-content']"
          >
            <slot name="before-content"></slot>
          </m-menu-item>
          <slot name="menu-content">
            <template v-if="options">
              <m-menu-item
                :key="option.key !== undefined ? option.key : option"
                v-for="option in options"
              >
                <slot
                  :hide="hide"
                  :item="option"
                  :selectItem="handleItemSelected"
                  name="menu-item"
                >
                  <a @click="handleItemSelected(option)" href="javascript:;">{{
                    option.text || option
                  }}</a>
                </slot>
              </m-menu-item>
            </template>
          </slot>
          <m-menu-item
            class="non-selectable"
            v-if="$scopedSlots['after-content']"
          >
            <slot name="after-content"> </slot>
          </m-menu-item>
        </m-menu>
      </slot>
    </template>
  </a-dropdown>
</template>

<script>
import Dropdown from 'ant-design-vue/es/dropdown'
import 'ant-design-vue/es/dropdown/style'
import MIcon from '../Icon/Icon.vue'
import MMenuItem from '../Menu/MenuItem.vue'
import MMenu from '../Menu/Menu.vue'
import Toggle from '../../mixins/toggle'

export default {
  name: 'MDropdown',
  model: {
    event: 'change'
  },
  components: {
    MIcon,
    ADropdown: Dropdown,
    MMenu,
    MMenuItem
  },
  props: {
    variant: {
      default: 'primary',
      type: String
    },
    triggerText: {
      default: 'Dropdown Menu',
      type: String
    },
    trigger: {
      default: () => ['click'],
      type: Array
    },
    /**
     * Options to use in dropdown items array of items(object)
     * @type {{new (): Array<{}>}}
     */
    options: {
      default: () => [],
      type: Array
    },
    placement: {
      default: 'bottomLeft',
      type: String
    },
    getPopupContainer: {
      type: Function
    }
  },
  computed: {
    selectedKeys() {
      return this.$attrs.value
        ? Array.isArray(this.$attrs.value)
          ? this.$attrs.value
          : [
            typeof this.$attrs.value === 'string'
              ? this.$attrs.value
              : this.$attrs.value.key
          ]
        : undefined
    }
  },
  methods: {
    getContainer() {
      const element = this.getPopupContainer ? this.getPopupContainer() : this.$el.closest('.__panel')
      if (element) {
        return element
      }
      return document.body
    },
    /**
     * When item is selected
     * @param {object} option
     * @return void
     */
    handleItemSelected(option) {
      this.$emit('change', option)
      this.visibleChange(false)
    }
  },
  mixins: [Toggle]
}
</script>
