<template>
  <a-switch
    :autoFocus="autoFocus"
    :defaultChecked="defaultChecked"
    :disabled="disabled"
    :checked="checked"
    :loading="loading"
    :size="size"
    @change="handleChange"
    v-bind="$attrs"
    v-on="listeners"
  >
    <template #checkedChildren v-if="$scopedSlots.checked">
      <slot name="checked" />
    </template>
    <template #unCheckedChildren v-if="$scopedSlots.unchecked">
      <slot name="unchecked" />
    </template>
  </a-switch>
</template>

<script>
import Switch from 'ant-design-vue/es/switch'
import 'ant-design-vue/es/switch/style'

export default {
  name: 'MSwitch',
  inheritAttrs: false,
  components: { ASwitch: Switch },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    autoFocus: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    defaultChecked: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    listeners() {
      const { change, input, blur, ...listeners } = this.$listeners
      return listeners
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    }
  }
}
</script>
