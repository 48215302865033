<script>
import Tabs from 'ant-design-vue/es/tabs';

export default {
  name: 'MTabPane',
  extends: Tabs.TabPane,
  components: { ATabPane: Tabs.TabPane },
  props: {
    forceRender: {
      type: Boolean,
      default: false
    },
    tab: {
      type: String
    }
  }
};
</script>
