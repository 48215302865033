<template>
  <a-collapse
    :accordion="accordion"
    :bordered="bordered"
    :defaultActiveKey="defaultActiveKey"
    :destroyInactivePanel="destroyInactivePanel"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="nestedSlot">
      <slot :name="name" v-bind="nestedSlot" />
    </template>
    <slot />
  </a-collapse>
</template>

<script>
import Collapse from 'ant-design-vue/es/collapse'
import 'ant-design-vue/es/collapse/style'

export default {
  name: 'MCollapse',
  components: { ACollapse: Collapse },
  props: {
    accordion: {
      type: Boolean,
      default: true,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    destroyInactivePanel: {
      type: Boolean,
      default: false,
    },
    defaultActiveKey: {
      type: [String, Array],
    },
  },
}
</script>
