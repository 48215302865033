<template>
  <a-auto-complete
    :allowClear="allowClear"
    :autoFocus="autoFocus"
    :backfill="backfill"
    :dataSource="dataSource"
    :defaultActiveFirstOption="defaultActiveFirstOption"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :dropdownMatchSelectWidth="dropdownMatchSelectWidth"
    :dropdownStyle="dropdownStyle"
    :filterOptionHandler="filterOptionHandler"
    :optionLabelProp="optionLabelProp"
    :placeholder="placeholder"
    @change="handleValueChange"
    @search="$emit('search', $event)"
    @dropdownVisibleChange="visibleChange"
    dropdownClassName="dropdownClassName"
    v-bind="attrs"
    v-on="listeners"
  >
    <template #dataSource>
      <slot name="options" />
    </template>
    <AInput @enterKey="$emit('pressEnter')" />
  </a-auto-complete>
</template>

<script>
import AutoComplete from 'ant-design-vue/es/auto-complete'
import 'ant-design-vue/es/auto-complete/style'
import Toggle from '../../mixins/toggle'
import AInput from 'ant-design-vue/es/input'

export default {
  name: 'MAutoComplete',
  components: { AAutoComplete: AutoComplete, AInput },
  model: {
    props: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [String, Array]
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    backfill: {
      type: Boolean
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    defaultActiveFirstOption: {
      type: Boolean,
      default: true
    },
    defaultValue: {
      type: [String, Array]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterOptionHandler: {
      type: [Function, Boolean]
    },
    optionLabelProp: {
      type: String
    },
    placeholder: {
      type: String,
      default: `Search...`
    },
    dropdownClassName: {
      type: String
    },
    dropdownMatchSelectWidth: {
      type: Boolean,
      default: true
    },
    dropdownStyle: {
      type: Object
    }
  },
  mixins: [Toggle],
  methods: {
    handleValueChange(value) {
      this.$emit('change', value)
    }
  },
  computed: {
    attrs() {
      const attrs = { ...this.$attrs }
      if (this.value) {
        attrs.value = this.value
      }
      return attrs
    },
    listeners() {
      const { visibleChange, change, search, ...listeners } = this.$listeners
      return listeners
    }
  }
}
</script>
