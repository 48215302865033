export default {
  props: {
    disabled: { type: Boolean, default: false },
    defaultOpen: { type: Boolean, default: undefined },
    open: { type: Boolean, default: undefined }
  },
  data() {
    return {
      currentOpen: this.defaultOpen || false
    }
  },
  computed: {
    isOpen: {
      get() {
        if (this.open !== undefined) {
          return this.open
        }
        return this.currentOpen
      },
      set(v) {
        if (this.open !== undefined) {
          return
        }
        this.currentOpen = v
      }
    }
  },
  mounted() {
    if (this.defaultOpen) {
      this.$emit('visibleChange', this.defaultOpen)
      this.$emit('show')
    }
  },
  methods: {
    /**
     * Triggered when visibility is change
     * @param {boolean} value
     * @return void
     */
    visibleChange(value) {
      if (this.disabled) {
        return
      }
      if (this.open !== undefined) {
        return
      }
      if (this.isOpen === value) {
        return
      }
      this.isOpen = value
      // this.$emit('visibleChange', value);
      if (value) {
        this.$emit('show')
      } else {
        this.$emit('hide')
      }
    },
    /**
     * Toggle visibility
     */
    toggle() {
      this.visibleChange(!this.isOpen)
    },
    /**
     * Make Visible
     */
    show() {
      this.visibleChange(true)
    },
    /**
     * Make it removed.
     */
    hide() {
      this.visibleChange(false)
    }
  }
}
