<template>
  <a-checkable-tag :checked="checked" @change="change" v-bind="$attrs">
    <slot/>
  </a-checkable-tag>
</template>

<script>
import Tag from 'ant-design-vue/es/tag';

export default {
  name: 'MCheckableTag',
  components: { ACheckableTag: Tag.CheckableTag },
  model: {
    prop: 'checked'
  },
  props: {
    checked: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String
    }
  },
  methods: {
    change() {
      this.$emit('input', !this.checked);
    }
  }
};
</script>
