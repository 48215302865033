<template>
  <a-steps
    :current="active"
    :direction="direction"
    :initial="initialActive"
    :size="size"
    :status="status"
  >
    <template #progressDot="slotData" v-if="$scopedSlots.progressDot">
      <slot name="progressDot" v-bind="slotData" />
    </template>
    <slot />
  </a-steps>
</template>

<script>
import Steps from 'ant-design-vue/es/steps';
import 'ant-design-vue/es/steps/style';

export default {
  name: 'MSteps',
  components: { ASteps: Steps },
  props: {
    initialActive: {
      type: Number
    },
    active: {
      type: Number
    },
    direction: {
      type: String
    },
    size: {
      type: String
    },
    status: {
      type: String
    }
  }
};
</script>
