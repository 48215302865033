import notification from 'ant-design-vue/es/notification';
import 'ant-design-vue/es/notification/style';

let notificationKey = 1;

const NotificationMaker = (type = 'info') => {
  if (!notification[type]) {
    throw new Error(`Notification of ${type} is not defined`);
  }
  return (
    options = {
      message: '',
      description: '',
      duration: 3,
      placement: 'topRight',
      onClose: null,
      key: notificationKey++
    }
  ) => notification[type](options);
};

// export { notification };

export default NotificationMaker;
