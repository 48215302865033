<template>
  <a-timeline :mode="mode" :reverse="reverse">
    <template #pending v-if="$scopedSlots.pending">
      <slot name="pending"/>
    </template>
    <template #pendingDot v-if="$scopedSlots.pendingDot">
      <slot name="pendingDot"/>
    </template>
    <slot/>
  </a-timeline>
</template>

<script>
import Timeline from 'ant-design-vue/es/timeline';
import 'ant-design-vue/es/timeline/style';

export default {
  name: 'MTimeline',
  components: { ATimeline: Timeline },
  props: {
    mode: {
      type: String,
      default: 'alternate'
    },
    reverse: {
      type: Boolean
    }
  }
};
</script>
