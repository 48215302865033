<template>
  <a-row
    :align="align == 'center' ? 'middle' : align"
    :gutter="gutter"
    :justify="justify"
    :type="type"
    class="row"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </a-row>
</template>

<script>
import Row from 'ant-design-vue/es/row';
import 'ant-design-vue/es/row/style';

export default {
  name: 'MRow',
  components: { ARow: Row },
  props: {
    gutter: {
      type: [Number, Object],
      required: false,
      default() {
        return { xs: 8, sm: 16, md: 24, lg: 32 };
      }
    },
    align: {
      type: String,
      required: false
    },
    justify: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'flex'
    }
  }
};
</script>
