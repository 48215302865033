<template>
  <a-checkbox
    :autoFocus="autoFocus"
    :checked="checked"
    :class="{ 'checkbox-info': variant === 'info' }"
    :defaultChecked="defaultChecked"
    :disabled="disabled"
    :indeterminate="indeterminate"
    :value="value"
    @change="handleCheckboxChange"
    v-on="listeners"
  >
    <slot />
  </a-checkbox>
</template>

<script>
import Checkbox from 'ant-design-vue/es/checkbox'
import 'ant-design-vue/es/checkbox/style'

export default {
  name: 'MCheckbox',
  components: { ACheckbox: Checkbox },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    value: {},
    variant: {
      type: String,
      required: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
    },
    defaultChecked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    indeterminate: {
      type: Boolean,
    },
  },
  methods: {
    handleCheckboxChange(event) {
      this.$emit('change', event.target.checked)
    },
  },
  computed: {
    listeners() {
      const { change, blur, ...listeners } = this.$listeners
      return listeners
    },
  },
}
</script>
