<template>
  <a-menu-divider v-bind="$attrs"/>
</template>

<script>
import Menu from 'ant-design-vue/es/menu';

export default {
  name: 'MMenuDivider',
  components: { AMenuDivider: Menu.Divider }
};
</script>
