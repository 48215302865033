<template>
  <a-list-item>
    <template #actions v-if="$scopedSlots.actions">
      <slot name="actions"/>
    </template>
    <template #extra v-if="$scopedSlots.extra">
      <slot name="extraContent"/>
    </template>
    <slot/>
  </a-list-item>
</template>

<script>
import Item from 'ant-design-vue/es/list/Item';

export default {
  name: 'MListItem',
  components: { AListItem: Item }
};
</script>
