<template>
  <a-button
    :block="block"
    :class="{
      [`button-${variant}`]: ['primary', 'dashed', 'danger', 'ghost', 'default'].indexOf(variant)<0,
      'button-shadow': shadow,
      'rounded': rounded
    }"
    :disabled="disabled"
    :ghost="outline"
    :loading="loading"
    :shape="shape"
    :size="size"
    :type="['primary', 'dashed', 'danger', 'ghost', 'default'].indexOf(variant) >= 0 ? variant : 'primary'"
    v-on="$listeners"
  >
    <slot>Button</slot>
  </a-button>
</template>

<script>
import Button from 'ant-design-vue/es/button';
import 'ant-design-vue/es/button/style';

export default {
  name: 'MButton',
  __ANT_BUTTON: true,
  components: { AButton: Button },
  props: {
    variant: {
      default: 'primary',
      required: false,
      type: String
    },
    size: {
      default: 'default',
      required: false,
      type: String
    },
    block: {
      default: false,
      required: false,
      type: Boolean
    },
    outline: {
      default: false,
      required: false,
      type: Boolean
    },
    loading: {
      default: false,
      required: false,
      type: [Boolean, Object]
    },
    shape: {
      required: false,
      type: String
    },
    shadow: {
      required: false,
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean
    },
    rounded: {
      type: Boolean,
      default: true
    }
  }
};
</script>
