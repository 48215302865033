<template>
  <a-input-search
    :defaultValue="defaultValue"
    ref="input"
    @change="handleChange"
    @pressEnter="handleEnter"
    @search="handleSearch"
    v-bind="attrs"
    v-on="listeners"
  >
    <template #enterButton v-if="$scopedSlots.default">
      <slot>
        <m-icon name="search" />
      </slot>
    </template>
  </a-input-search>
</template>

<script>
import Search from 'ant-design-vue/es/input/Search'
import { MIcon } from '../'
export default {
  name: 'MInputSearch',
  components: {
    AInputSearch: Search,
    MIcon
  },
  props: {
    value: {
      type: [String, Object, Array]
    },
    type: {
      type: String,
      default: 'text'
    },
    defaultValue: {
      type: String
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e.target.value)
    },
    handleSearch(value) {
      this.$emit('search', value)
    },
    handleEnter(e) {
      if (this.type === 'search') {
        e.preventDefault()
        e.stopPropagation()
        this.handleSearch(e.target.value)
      }
      this.$emit('enterKey', e)
    },
    focus() {
      this.$refs.input.focus()
    }
  },
  computed: {
    listeners() {
      const { input, pressEnter, search, ...listeners } = this.$listeners
      return listeners
    },
    attrs() {
      const attrs = { ...this.$attrs }
      if (this.innerValue) {
        attrs.value = this.innerValue
      }
      return attrs
    },
    innerValue() {
      return this.value
    }
  }
}
</script>
