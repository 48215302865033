<template>
  <component
    :autoFocus="autoFocus"
    :checked="checked"
    :class="{ 'radio-info': variant === 'info' }"
    :defaultChecked="defaultChecked"
    :disabled="disabled"
    :is="componentDefinition"
    :value="value"
    @click="$emit('click')"
  >
    <slot />
  </component>
</template>

<script>
import Radio from 'ant-design-vue/es/radio'
import 'ant-design-vue/es/radio/style'

export default {
  name: 'MRadio',
  components: { ARadio: Radio, ARadioButton: Radio.Button },
  props: {
    variant: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    value: {},
    autoFocus: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean
    },
    defaultChecked: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    componentDefinition() {
      if (this.type === 'button') {
        return 'a-radio-button'
      }
      return 'a-radio'
    }
  }
}
</script>
