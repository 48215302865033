<template>
  <a-col
    :class="{ 'auto-size': autoSize, 'fixed-size': size }"
    :lg="lg"
    :md="md"
    :offset="offset"
    :order="order"
    :pull="pull"
    :push="push"
    :sm="sm"
    :span="size"
    :xl="xl"
    :xs="xs"
    :xxl="xxl"
    class="col"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot/>
  </a-col>
</template>

<script>
import Col from 'ant-design-vue/es/col';
import 'ant-design-vue/es/col/style';

export default {
  name: 'MCol',
  components: { ACol: Col },
  props: {
    size: {
      required: false,
      type: Number
    },
    push: {
      required: false,
      type: Number
    },
    pull: {
      type: Number,
      required: false
    },
    order: {
      type: Number,
      required: false
    },
    offset: {
      type: Number,
      required: false
    },
    xs: {
      type: [Number, Object],
      required: false
    },
    sm: {
      type: [Number, Object],
      required: false
    },
    md: {
      type: [Number, Object],
      required: false
    },
    lg: {
      type: [Number, Object],
      required: false
    },
    xl: {
      type: [Number, Object],
      required: false
    },
    xxl: {
      type: [Number, Object],
      required: false
    },
    autoSize: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style scoped lang="less">
.col {
  flex-grow: 1;
  &.auto-size,
  &.fixed-size {
    flex-grow: unset;
  }
}
</style>
