<template>
  <form :class="formClass" @submit.prevent="handleSubmitted">
    <slot />
    <slot :reset="reset" name="submit">
      <m-row justify="end">
        <m-col auto-size>
          <m-button :loading="loading" class="mx-1" type="submit">
            {{ submitText }}
          </m-button>
          <slot name="reset">
            <m-button @click="reset" v-if="allowReset" variant="default">
              {{ resetText }}
            </m-button>
          </slot>
        </m-col>
      </m-row>
    </slot>
  </form>
</template>

<script>
import 'ant-design-vue/es/form/style'
import { MRow, MCol } from '../'

export default {
  name: 'MForm',
  components: {
    MRow,
    MCol
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-form'
    },
    hideRequiredMark: {
      type: Boolean
    },
    layout: {
      type: String,
      default: 'vertical'
    },
    submitText: {
      type: String,
      default: 'Submit'
    },
    allowReset: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    resetText: {
      type: String
    }
  },
  provide() {
    return {
      parentForm: {
        layout: this.layout
      }
    }
  },
  methods: {
    handleSubmitted(e) {
      e.stopPropagation()
      e.stopImmediatePropagation()
      this.$emit('submit', e)
    },
    reset() {
      this.$emit('reset')
    }
  },
  computed: {
    formClass() {
      return [`${this.prefixCls}`, `${this.prefixCls}-${this.layout}`]
    }
  }
}
</script>
