<template>
  <a-tree-select
    :allowClear="allowClear"
    :getPopupContainer="getContainer"
    :disabled="disabled"
    :dropdownClassName="dropdownClassName"
    :dropdownMatchSelectWidth="dropdownMatchSelectWidth"
    :dropdownStyle="dropdownStyle"
    :filterTreeNode="filterTreeNode"
    :labelInValue="labelInValue"
    :loadData="loadData"
    :multiple="multiple"
    :placeholder="placeholder"
    :showCheckedStrategy="stretagy"
    :showSearch="showSearch"
    :size="size"
    :treeCheckable="treeCheckable"
    :treeData="options"
    :treeNodeFilterProp="treeNodeFilterProp"
    :treeNodeLabelProp="treeNodeLabelProp"
    :value="value"
    @change="onChange"
  >
    <template #suffixIcon>
      <slot name="suffixIcon">
        <MIcon name="chevron-down" class="text-neutral-light" />
      </slot>
    </template>
    <template #clearIcon>
      <slot name="clearIcon">
        <MIcon name="times-circle" type="fas" />
      </slot>
    </template>
  </a-tree-select>
</template>

<script>
import ATreeSelect from 'ant-design-vue/es/tree-select'
import 'ant-design-vue/es/tree-select/style'

export default {
  name: 'MTreeSelect',
  components: { ATreeSelect },
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: [String, Array, Number]
    },
    options: {
      required: true,
      type: Array
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      defualt: false
    },
    dropdownClassName: {
      type: String
    },
    dropdownMatchSelectWidth: {
      type: Boolean,
      default: false
    },
    dropdownStyle: {
      type: Object
    },
    filterTreeNode: {
      type: [Boolean, Function]
    },
    labelInValue: {
      type: Boolean
    },
    loadData: {
      type: Function
    },
    multiple: {
      type: Boolean
    },
    treeCheckable: {
      type: Boolean
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    size: {
      type: String
    },
    treeNodeLabelProp: {
      type: String,
      default: 'label'
    },
    treeNodeFilterProp: {
      type: String,
      default: 'label'
    },
    getPopupContainer: {
      type: Function
    }
  },
  data() {
    return {
      stretagy: ATreeSelect.SHOW_ALL
    }
  },
  methods: {
    getContainer() {
      const element = this.getPopupContainer
        ? this.getPopupContainer()
        : this.$el.closest('.__panel')
      if (element) {
        return element
      }
      return document.body
    },
    onChange(...args) {
      this.$emit('change', ...args)
    }
  }
}
</script>
