import Confirm from './confirm'
import toast from './toast'
import NotificationMaker from './notification'

const successToast = toast('success')
const errorToast = toast('error')
const loadingToast = toast('loading')
const errorNotification = NotificationMaker('error')
const successNotification = NotificationMaker('success')
const infoNotification = NotificationMaker('info')

const mapping = {
  $confirm: Confirm,
  $toast: toast('info'),
  $successToast: successToast,
  $errorToast: errorToast,
  $warningToast: toast('warning'),
  $loading: loadingToast,
  $notification: infoNotification,
  $successNotification: successNotification,
  $errorNotification: errorNotification,
  $warningNotification: NotificationMaker('warning')
}

export {
  successToast,
  errorToast,
  loadingToast,
  infoNotification,
  errorNotification,
  successNotification
}

export default mapping
