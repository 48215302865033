<template>
  <span>
    <slot
      :close="hide"
      :open="show"
      :toggle="toggle"
      name="trigger"
      v-if="$scopedSlots.trigger"
    >
      <m-button @click="show">Open Modal</m-button>
    </slot>
    <a-modal
      :centered="centered"
      :closable="false"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      :visible="isOpen"
      :width="width"
      :wrapClassName="overlayClassName"
      @cancel="handleCancel"
      @change="visibleChange"
      @ok="handleSuccess"
    >
      <template #title v-if="$scopedSlots.title">
        <slot name="title">
          <h4 class="m-0">Modal Title</h4>
        </slot>
      </template>
      <template slot="footer" v-if="$scopedSlots.footer">
        <slot :cancel="handleCancel" :success="handleSuccess" name="footer">
          <m-button @click="handleCancel" variant="default">Cancel</m-button>
          <m-button @click="handleSuccess">Ok</m-button>
        </slot>
      </template>
      <slot />
    </a-modal>
  </span>
</template>

<script>
import Modal from 'ant-design-vue/es/modal'
import 'ant-design-vue/es/modal/style'
import { MButton } from '../'
import Toggle from '../../mixins/toggle'

export default {
  name: 'MModal',
  components: {
    MButton,
    AModal: Modal
  },
  mixins: [Toggle],
  props: {
    preventAutoCloseOnConfirm: { type: Boolean, default: false },
    centered: {
      type: Boolean
    },
    confirmLoading: {
      type: Boolean
    },
    width: {
      type: Number
    },
    overlayClassName: {
      type: String
    }
  },
  methods: {
    /**
     * When success button of modal is pressed
     */
    handleSuccess() {
      this.$emit('success')
      if (!this.preventAutoCloseOnConfirm) {
        this.visibleChange(false)
      }
    },
    /**
     * When cancel button of modal is pressed
     */
    handleCancel() {
      this.$emit('cancel')
      this.visibleChange(false)
    }
  }
}
</script>
