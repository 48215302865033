<template>
  <a-popover
    :destroyTooltipOnHide="destroyTooltipOnHide"
    :getPopupContainer="getContainer"
    :mouseEnterDelay="mouseEnterDelay"
    :mouseLeaveDelay="mouseLeaveDelay"
    :overlayClassName="overlayClassName"
    :overlayStyle="overlayStyle"
    :placement="placement"
    :transitionName="transitionName"
    :trigger="trigger"
    :visible="isOpen"
    @visibleChange="visibleChange"
  >
    <template slot="title" v-if="$scopedSlots.title">
      <slot :hide="hide" :show="show" :toggle="toggle" name="title">title</slot>
    </template>
    <template slot="content">
      <slot :hide="hide" :show="show" :toggle="toggle">Sample Popover</slot>
    </template>
    <slot :hide="hide" :show="show" :toggle="toggle" name="trigger"
      >Popover trigger</slot
    >
  </a-popover>
</template>

<script>
import Popover from 'ant-design-vue/es/popover'
import 'ant-design-vue/es/popover/style'
import Toggle from '../../mixins/toggle'

export default {
  name: 'MPopover',
  components: { APopover: Popover },
  mixins: [Toggle],
  props: {
    trigger: {
      type: String,
      default: 'click'
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    transitionName: {
      type: String
    },
    mouseEnterDelay: {
      type: Number
    },
    mouseLeaveDelay: {
      type: Number
    },
    overlayClassName: {
      type: String
    },
    overlayStyle: {
      type: Object
    },
    visible: {
      type: Boolean
    },
    getPopupContainer: {
      type: Function
    },
    destroyTooltipOnHide: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getContainer() {
      const element = this.getPopupContainer
        ? this.getPopupContainer()
        : this.$el.closest('.__panel')
      if (element) {
        return element
      }
      return document.body
    }
  }
}
</script>
