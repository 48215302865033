<template>
  <i class="anticon" v-on="$listeners">
    <font-awesome-icon
      :border="border"
      :fixed-width="fixedWidth"
      :flip="flip"
      :icon="[type, name]"
      :pulse="pulse"
      :rotation="rotate"
      :size="size"
      :spin="spin"
      :transform="transform"
      v-bind="$attrs"
    />
  </i>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
export default {
  name: 'MIcon',
  components: {
    FontAwesomeIcon
  },
  props: {
    type: {
      default: 'fal',
      type: String
    },
    name: {
      type: String
    },
    size: {
      type: String
      //   default: 'lg'
    },
    fixedWidth: {
      type: Boolean,
      default: false
    },
    rotate: {
      type: String
    },
    flip: {
      type: String
    },
    border: {
      type: Boolean
    },
    spin: {
      type: Boolean
    },
    pulse: {
      type: Boolean
    },
    transform: {
      type: [String, Object]
    }
  }
};
</script>
