<template>
  <a-layout-sider
    :breakpoint="breakpoint"
    :collapsedWidth="collapsedWidth"
    :collapsible="collapsible"
    :reverseArrow="reverseArrow"
    :theme="theme"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #trigger v-if="$scopedSlots.trigger">
      <slot name="trigger"/>
    </template>
    <slot/>
  </a-layout-sider>
</template>

<script>
import Layout from 'ant-design-vue/es/layout';

export default {
  name: 'MLayoutSider',
  components: { ALayoutSider: Layout.Sider },
  props: {
    breakpoint: {
      type: Object
    },
    collapsedWidth: {
      type: Number
    },
    collapsible: {
      type: Boolean
    },
    reverseArrow: {
      type: Boolean
    },
    theme: {
      type: String,
      default: 'dark'
    }
  }
};
</script>
