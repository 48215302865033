<template>
  <a-tabs
    :animated="animated"
    :class="[variant]"
    :defaultActiveKey="defaultActive"
    :size="size"
    :tabBarGutter="tabBarGutter"
    :tabBarStyle="tabBarStyle"
    :tabPosition="position"
    :type="type"
    @change="handleChange"
    v-bind="attrs"
    v-on="listeners"
  >
    <slot />
    <template #tabBarExtraContent>
      <slot name="tabBarExtraContent"></slot>
    </template>
  </a-tabs>
</template>

<script>
import Tabs from 'ant-design-vue/es/tabs';
import 'ant-design-vue/es/tabs/style';

export default {
  name: 'MTab',
  components: { ATabs: Tabs },
  model: {
    event: 'change'
  },
  props: {
    variant: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number]
    },
    defaultActive: {
      type: String
    },
    animated: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    },
    tabBarStyle: {
      type: Object
    },
    position: {
      type: String,
      default: 'top'
    },
    type: {
      type: String,
      default: 'line'
    },
    tabBarGutter: {
      type: Number
    }
  },
  computed: {
    listeners() {
      const { change, ...listeners } = this.$listeners;
      return listeners;
    },
    attrs() {
      const { ...attrs } = this.$attrs;
      if (this.value) {
        attrs.activeKey = this.value;
      }
      return attrs;
    }
  },
  methods: {
    handleChange(tab) {
      this.$emit('change', tab);
    }
  }
};
</script>
