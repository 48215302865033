var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-divider',{class:{
    'orientation-end': _vm.orientation === 'end',
    'orientation-start': _vm.orientation === 'start',
    'dark-variant': _vm.darkVariant,
  },attrs:{"dashed":_vm.dashed,"orientation":_vm.orientation === 'end'
      ? 'right'
      : _vm.orientation === 'start'
      ? 'left'
      : _vm.orientation,"type":_vm.type}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }