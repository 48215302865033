<template>
  <span>
    <slot :close="hide" :open="show" :toggle="toggle" name="trigger">
      <m-button @click="show">Open Drawer</m-button>
    </slot>
    <a-drawer
      :closable="closable"
      :destroyOnClose="true"
      :height="height"
      :placement="placement"
      :visible="isOpen"
      :width="width"
      v-bind="$attrs"
      @close="hide"
      v-on="$listeners"
    >
      <template #title>
        <slot name="title">
          <h4 class="mb-0">Drawer Heading</h4>
        </slot>
      </template>
      <slot />
    </a-drawer>
  </span>
</template>

<script>
import Drawer from 'ant-design-vue/es/drawer';
import 'ant-design-vue/es/drawer/style';
import { MButton } from '../';
import Toggle from '../../mixins/toggle';

export default {
  name: 'MDrawer',
  mixins: [Toggle],
  components: {
    MButton,
    ADrawer: Drawer
  },
  props: {
    defaultOpen: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'right'
    },
    closable: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: '45%'
    },
    height: {
      type: [String, Number],
      default: 180
    }
  }
};
</script>
