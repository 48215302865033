<template>
  <a-list
    :bordered="bordered"
    :dataSource="data"
    :grid="gridProps"
    :itemLayout="itemLayout"
    :locale="locale"
    :pagination="pagination"
    :position="paginationPosition"
    :rowKey="rowKey"
    :split="split"
  >
    <template #header v-if="$scopedSlots.header">
      <slot name="header"/>
    </template>
    <template #renderItem="item, index">
      <slot :item="item" name="item"/>
    </template>
    <template #loadMore v-if="$scopedSlots.loadMore">
      <slot name="loadMore"/>
    </template>
    <template #footer v-if="$scopedSlots.footer">
      <slot name="footer"/>
    </template>
  </a-list>
</template>

<script>
import List from 'ant-design-vue/es/list';
import 'ant-design-vue/es/list/style';

export default {
  name: 'MList',
  components: { AList: List },
  props: {
    data: {
      type: Array,
      required: true
    },
    bordered: {
      type: Boolean,
      default: false
    },
    itemLayout: {
      type: String,
      default: 'horizontal'
    },
    locale: {
      type: String
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    },
    split: {
      type: Boolean
    },
    rowKey: {
      type: Function,
      default: ({ key, id }) => key || id
    },
    paginationPosition: {
      type: String
    },
    grid: {
      type: Object
      // default: () => ({ gutter: 16 })
    }
  },
  computed: {
    gridProps() {
      if (!this.grid) {
        return undefined;
      }
      const gridObject = {
        ...this.grid,
        xs: this.getGrid('xs'),
        sm: this.getGrid('sm'),
        md: this.getGrid('md'),
        lg: this.getGrid('lg'),
        xl: this.getGrid('xl'),
        xxl: this.getGrid('xxl'),
        column: this.getGrid('column')
      };
      const keys = Object.keys(gridObject).filter(k => Boolean(gridObject[k]));
      const finalObject = {};
      keys.forEach(k => (finalObject[k] = gridObject[k]));
      return finalObject;
    }
  },
  methods: {
    /**
     * @param {string} t type of grid to access
     */
    getGrid(t) {
      return this.grid && this.grid[t] && parseInt(this.grid[t]) * 2;
    }
  }
};
</script>
