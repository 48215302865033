<template>
  <a-select
    :getPopupContainer="getContainer"
    :allowClear="allowClear"
    :autoClearSearchValue="autoClearSearchValue"
    :autoFocus="autoFocus"
    :defaultActiveFirstOption="defaultActiveFirstOption"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :dropdownClassName="dropdownClassName"
    :dropdownMatchSelectWidth="dropdownMatchSelectWidth"
    :dropdownStyle="dropdownStyle"
    :filterOption="filterOption"
    :firstActiveValue="firstActiveValue"
    :labelInValue="labelInValue"
    :loading="loading"
    :maxTagCount="maxTagCount"
    :mode="mode"
    :open="isOpen"
    :optionFilterProp="optionFilterProp"
    :optionLabelProp="optionLabelProp"
    :placeholder="placeholder"
    :showArrow="showArrow"
    :showSearch="showSearch"
    :size="size"
    :tokenSeparators="tokenSeparators"
    @blur="$emit('blur', $event)"
    @deselect="handleDeselect"
    @dropdownVisibleChange="visibleChange"
    @focus="$emit('focus', $emit)"
    @inputKeydown="$emit('inputKeydown', $event)"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    @popupScroll="$emit('popupScroll', $event)"
    @search="handleSearch"
    @select="handleSelect"
    v-model="innerValue"
    v-on="listeners"
  >
    <slot>
      <template v-if="options.length">
        <a-select-option
          :key="option.key || option.value || option.id"
          :label="option.text || option.label || option.title || option.name"
          :value="option.value || option.key || option.id"
          :disabled="option.disabled"
          v-for="option in options"
        >
          <slot name="option" :option="option">
            {{ option.text || option.label || option.title || option.name }}
          </slot>
        </a-select-option>
      </template>
    </slot>
    <template #suffixIcon>
      <slot name="suffixIcon">
        <MIcon name="chevron-down" v-if="!loading" class="text-neutral-light" />
        <MIcon name="spinner-third" class="fa-spin text-neutral-light" v-else />
      </slot>
    </template>
    <template #removeIcon>
      <slot name="removeIcon">
        <MIcon name="times" size="lg" class="text-neutral-light" />
      </slot>
    </template>
    <template #clearIcon>
      <slot name="clearIcon">
        <MIcon name="times-circle" type="fas" />
      </slot>
    </template>
    <template #menuItemSelectedIcon>
      <slot name="menuItemSelectedIcon">
        <MIcon name="check" size="lg" />
      </slot>
    </template>
    <template #notFoundContent>
      <slot name="notFoundContent">
        <MNoData size="small" />
      </slot>
    </template>
    <template #dropdownRender="scope" v-if="$scopedSlots.dropdownRender">
      <slot name="dropdownRender" v-bind="scope" />
    </template>
  </a-select>
</template>

<script>
import Select from 'ant-design-vue/es/select'
import 'ant-design-vue/es/select/style'
import Toggle from '../../mixins/toggle'
import MNoData from '../Data/NoData'

export default {
  name: 'MSelect',
  components: { ASelect: Select, ASelectOption: Select.Option, MNoData },
  mixins: [Toggle],
  model: {
    event: 'change'
  },
  props: {
    value: {},
    allowClear: {
      type: Boolean,
      default: true
    },
    autoClearSearchValue: {
      type: Boolean,
      default: true
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    defaultActiveFirstOption: {
      type: Boolean,
      default: true
    },
    defaultValue: {},
    disabled: {
      type: Boolean,
      default: false
    },
    dropdownClassName: {
      type: String
    },
    dropdownMatchSelectWidth: {
      type: Boolean,
      default: true
    },
    dropdownStyle: {
      type: Object
    },
    filterOption: {
      type: [Boolean, Function],
      default: (input, option) =>
        option
          ? (option.text || option.label || option.title || option.name || '')
            .toLowerCase()
            .indexOf((input || '').toLowerCase()) >= 0
          : true
    },
    firstActiveValue: {
      type: [String, Array]
    },
    labelInValue: {
      type: Boolean
      // default: true
    },
    maxTagCount: {
      type: Number
    },
    mode: {
      type: String,
      default: 'default'
    },
    optionFilterProp: {
      type: String,
      default: 'label'
    },
    optionLabelProp: {
      type: String,
      default() {
        return 'label'
      }
    },
    placeholder: {
      type: String,
      default: 'Select...'
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'default'
    },
    tokenSeparators: {
      type: [Array]
    },
    options: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    getPopupContainer: {
      type: Function
    }
  },
  computed: {
    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },
    innerValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('change', newValue)
      }
    }
  },
  methods: {
    getContainer() {
      const element = this.getPopupContainer
        ? this.getPopupContainer()
        : this.$el.closest('.__panel')
      if (element) {
        return element
      }
      return document.body
    },
    handleDeselect(value, option) {
      this.$emit('deselect', value, option)
    },
    handleSearch(value) {
      this.$emit('search', value)
    },
    handleSelect(value, option) {
      this.$emit('select', value, option)
    }
  }
}
</script>
