<template>
  <a-list-item-meta>
    <template #avatar v-if="$scopedSlots.avatar || avatarSrc">
      <slot name="avatar">
        <m-avatar :src="avatarSrc"/>
      </slot>
    </template>
    <template #title v-if="$scopedSlots.title">
      <slot name="title"/>
    </template>
    <template #description>
      <slot/>
    </template>
  </a-list-item-meta>
</template>

<script>
import List from 'ant-design-vue/es/list';

export default {
  name: 'MListItemMeta',
  components: { AListItemMeta: List.Item.Meta },
  props: {
    avatarSrc: {
      type: String
    }
  }
};
</script>
