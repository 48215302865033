import Modal from 'ant-design-vue/es/modal';

export default function confirm(
  {
    title = 'Are you sure?',
    message = '',
    okText = 'Yes',
    okType = 'danger',
    cancelText = 'No',
    okButtonProps = {},
    cancelButtonProps = {},
    centered = true,
    onOk = null,
    onCancel = null
  },
  type = 'confirm'
) {
  const modalOptions = {
    title,
    content: message,
    okText,
    okType,
    cancelText,
    cancelButtonProps,
    okButtonProps,
    centered,
    onOk,
    onCancel
  };
  if (!Modal[type]) {
    throw new Error(`${type} of modal is not defined`);
  }
  if (onOk || onCancel) {
    const modal = Modal[type](modalOptions);
    return Promise.resolve(modal);
  }
  return new Promise((resolve, reject) => {
    modalOptions.onOk = () => resolve();
    modalOptions.onCancel = () => reject(new Error('cancelled'));
    Modal[type](modalOptions);
  });
}
