<template>
  <a-card
    :bodyStyle="bodyStyle"
    :bordered="bordered"
    :class="{'card-shadow': shadow}"
    :headStyle="headStyle"
    :hoverable="hoverable"
    :loading="loading"
    :type="type"
  >
    <template #actions v-if="$scopedSlots.actions">
      <slot name="actions"/>
    </template>
    <template #cover v-if="$scopedSlots.cover">
      <slot name="cover"/>
    </template>
    <template #extra v-if="$scopedSlots.extra">
      <slot name="extra"/>
    </template>
    <template #title v-if="$scopedSlots.header">
      <slot name="header"/>
    </template>
    <slot/>
  </a-card>
</template>

<script>
import Card from 'ant-design-vue/es/card';
import 'ant-design-vue/es/card/style';

export default {
  name: 'MCard',
  components: { ACard: Card },
  props: {
    shadow: {
      type: Boolean
    },
    headStyle: {
      type: Object
    },
    bodyStyle: {
      type: Object
    },
    bordered: {
      type: Boolean,
      default: true
    },
    hoverable: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    type: {
      type: String
    }
  }
};
</script>
