<template>
  <a-checkbox-group
    :defaultValue="defaultValue"
    :disabled="disabled"
    :options="options"
    :value="value"
    @change="handleChange"
    v-on="listeners"
  >
    <template #label="scope" v-if="$scopedSlots.default">
      <slot :option="scope.option"/>
    </template>
    <slot/>
  </a-checkbox-group>
</template>

<script>
import Checkbox from 'ant-design-vue/es/checkbox';

export default {
  name: 'MCheckboxGroup',
  components: { ACheckboxGroup: Checkbox.Group },
  model: {
    event: 'change'
  },
  props: {
    defaultValue: {
      type: [Array]
    },
    disabled: {
      type: Boolean
    },
    value: {
      type: Array
    },
    options: {
      type: Array
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    }
  },
  computed: {
    listeners() {
      const { change, ...listeners } = this.$listeners;
      return listeners;
    }
  }
};
</script>
