<template>
  <a-form-item
    :colon="colon"
    :hasFeedback="hasFeedback"
    :help="error"
    :label="label"
    :labelCol="labelSize"
    :required="required"
    :validateStatus="validateStatus"
    :wrapperCol="wrapperSize"
    v-bind="$scopedSlots.label ? {} : { label }"
  >
    <template #label v-if="$scopedSlots.label">
      <slot name="label" />
    </template>
    <slot />
  </a-form-item>
</template>

<script>
import FormItem from 'ant-design-vue/es/form/FormItem'

export default {
  name: 'MFormItem',
  components: { AFormItem: FormItem },
  inject: {
    parentForm: { default: null },
  },
  props: {
    value: {},
    validateStatus: {
      type: String,
    },
    error: {
      type: String,
    },
    label: {
      type: String,
    },
    colon: {
      type: Boolean,
      default: false,
    },
    hasFeedback: {
      type: Boolean,
      default: false,
    },
    labelCol: {
      type: Object,
      default() {
        return { span: 2 }
      },
    },
    wrapperCol: {
      type: Object,
      default() {
        return { span: 10 }
      },
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    labelSize() {
      if (this.parentForm) {
        if (this.parentForm.layout === 'horizontal') {
          return this.labelCol
        }
      }
      return undefined
    },
    wrapperSize() {
      if (this.parentForm) {
        if (this.parentForm.layout === 'horizontal') {
          return this.wrapperCol
        }
      }
      return undefined
    },
  },
}
</script>
