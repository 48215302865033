<template>
  <a-date-picker
    :allowClear="allowClear"
    :getCalendarContainer="getContainer"
    :autoFocus="autoFocus"
    :defaultPickerValue="defaultPickerValue"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :disabledDate="getDisabledDate()"
    :disabledTime="getDisabledTime()"
    :dropdownClassName="dropdownClassName"
    :format="currentFormat"
    :open="isOpen"
    :placeholder="placeholder"
    :popupStyle="popupStyle"
    :showTime="shouldShowTime"
    :size="size"
    @calendarChange="handleCalendarChange"
    @change="handleChange"
    @ok="handleConfirm"
    @openChange="handleOpenChange"
    style="width: 100%"
    v-bind="attrs"
  >
    <template #suffixIcon>
      <m-icon :name="calendarIcon" />
    </template>
    <template #dateRender="current, today" v-if="$scopedSlots.dateRender">
      <slot :current="current" :today="today" name="dateRender" />
    </template>
  </a-date-picker>
</template>

<script>
import DatePicker from 'ant-design-vue/es/date-picker'
import 'ant-design-vue/es/date-picker/style'
import Moment from 'moment'
import { MIcon } from '../'
import BaseMixin from './BaseMixin'

export default {
  name: 'MDatePicker',
  mixins: [BaseMixin],
  components: {
    MIcon,
    ADatePicker: DatePicker,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number, Object],
    },
    defaultValue: {
      type: Object,
    },
    defaultPickerValue: {
      type: Object,
    },
    disabledTime: {
      type: Function,
    },
    format: {
      type: String,
    },
    showTime: {
      type: [Boolean, Object],
      default() {
        return {
          use12Hours: true,
          format: 'hh:mm A',
        }
      },
    },
    placeholder: {
      default: 'Select...',
    },
    minDate: {
      type: [String, Number, Object],
    },
    maxDate: {
      type: [String, Number, Object],
    },
    getPopupContainer: {
      type: Function,
    },
    dateFormat: {
      type: 'String',
    },
    timeFormat: {
      type: [Object, String],
    },
  },
  data() {
    return {
      internalValue: this.getInstanceFromString(this.value),
      lastSelectedDate: null,
    }
  },
  computed: {
    calendarIcon() {
      if (this.showTime) {
        return 'clock'
      }
      return 'calendar-alt'
    },
    attrs() {
      const attrs = { ...this.$attrs }
      attrs.value = this.internalValue
      return attrs
    },
    shouldShowTime() {
      if (this.showTime) {
        if (this.timeFormat) {
          return this.timeFormat
        }
        return {
          use12Hours: true,
          format: 'hh:mm A',
        }
      }
      return false
    },
    currentFormat() {
      if (this.dateFormat) {
        return this.dateFormat
      }
      return this.format || this.showTime ? 'DD/MM/YYYY hh:mm A' : 'DD/MM/YYYY'
    },
  },
  watch: {
    value(newValue) {
      this.internalValue = this.getInstanceFromString(newValue)
    },
  },
  methods: {
    getContainer() {
      const element = this.getPopupContainer
        ? this.getPopupContainer()
        : this.$el.closest('.__panel')
      if (element) {
        return element
      }
      return document.body
    },
    getDisabledDate() {
      if (this.minDate || this.maxDate) {
        return (current) => {
          const onlyDateCurrent = Moment(
            current.format('DD/MM/YYYY'),
            'DD/MM/YYYY'
          )
          const minDate = this.minDate
            ? this.getInstanceFromStringWithoutFormat(this.minDate).format(
                'YYYY-MM-DD'
              )
            : undefined
          const maxDate = this.maxDate
            ? this.getInstanceFromStringWithoutFormat(this.maxDate).format(
                'YYYY-MM-DD'
              )
            : undefined
          if (minDate && maxDate) {
            return !(
              onlyDateCurrent.isSameOrAfter(minDate) &&
              onlyDateCurrent.isSameOrBefore(maxDate)
            )
          }
          if (minDate) {
            return !onlyDateCurrent.isSameOrAfter(minDate)
          }
          if (maxDate) {
            return !onlyDateCurrent.isSameOrBefore(maxDate)
          }
        }
      }
      return this.disabledDate
    },
    getDisabledTime() {
      if (this.minDate || this.maxDate) {
        return (selectedDate) => {
          // const minDate = this.minDate
          //   ? this.getInstanceFromStringWithoutFormat(this.minDate)
          //   : undefined
          // const maxDate = this.maxDate
          //   ? this.getInstanceFromStringWithoutFormat(this.maxDate)
          //   : undefined
          let disabledHours = []
          let disabledMinutes = []
          // @TODO check 3 cases for current date and select which hours and minutes needs to be disabled
          // if (minDate) {
          //   return !current.isSameOrAfter(minDate)
          // }
          // if (maxDate) {
          //   return !current.isSameOrBefore(maxDate)
          // }
          return {
            disabledHours: () => disabledHours,
            disabledMinutes: () => disabledMinutes,
          }
        }
      }
      return this.disabledTime
    },
    // @TODO allow string format parsing and timestamp parsing remove this function or update it so it can parse all values
    getInstanceFromStringWithoutFormat(string) {
      if (typeof string === 'number') {
        return Moment.unix(string / 1000)
      }
      return Moment(string)
    },
    // @TODO allow string format parsing and timestamp parsing
    getInstanceFromString(dateString) {
      if (!dateString) {
        return undefined
      }
      if (Moment.isMoment(this.value)) {
        return this.value
      }
      return Moment.unix(this.value / 1000)
    },
    handleCalendarChange(...args) {
      this.$emit('handleCalendarChange', ...args)
    },
    handleChange(date, dateStr) {
      if (!this.showTime) {
        this.lastSelectedDate = date
        this.$emit('change', date ? date.unix() * 1000 : undefined, date)
        this.$emit('update', date ? date.unix() * 1000 : undefined, date)
      } else {
        if (!date) {
          this.$emit('change', undefined)
          this.$emit('update', undefined)
        } else if (Math.abs(date.diff(Moment())) <= 2) {
          // now button is clicked
          this.$emit('change', date.unix() * 1000, date)
          this.$emit('update', date.unix() * 1000, date)
        }
        this.internalValue = date
      }
    },
    handleConfirm() {
      this.lastSelectedDate = this.internalValue
      this.$emit('change', this.internalValue.unix() * 1000, this.internalValue)
      this.$emit('update', this.internalValue.unix() * 1000, this.internalValue)
    },
    resetValue() {
      if (this.value) {
        this.internalValue = this.getInstanceFromString(this.value)
      } else if (this.lastSelectedDate) {
        this.internalValue = this.lastSelectedDate
      } else {
        this.internalValue = undefined
      }
    },
    handleOpenChange(value) {
      if (!value) {
        this.$nextTick(() => this.resetValue())
      }
      this.visibleChange(value)
    },
  },
}
</script>
