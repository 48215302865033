<template>
  <a-timeline-item :color="color">
    <template #dot v-if="$scopedSlots.dot">
      <slot name="dot"/>
    </template>
    <slot/>
  </a-timeline-item>
</template>

<script>
import Timeline from 'ant-design-vue/es/timeline';

export default {
  name: 'MTimelineItem',
  components: { ATimelineItem: Timeline.Item },
  props: {
    color: {
      type: String
    }
  }
};
</script>
