<script>
import Steps from 'ant-design-vue/es/steps'

export default {
  name: 'MStep',
  extends: Steps.Step,
  // components: { AStep: Steps.Step },
  props: {
    status: {
      type: String
    }
  }
}
</script>
