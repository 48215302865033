<template>
  <a-badge
    :count="count"
    :dot="dot"
    :numberStyle="numberStyle"
    :offset="offset"
    :overflowCount="overflowCount"
    :showZero="showZero"
    :status="status"
    :text="text"
    :title="title"
  >
    <template #count v-if="!count">
      <slot name="count"></slot>
    </template>
    <slot/>
  </a-badge>
</template>

<script>
import Badge from 'ant-design-vue/es/badge';
import 'ant-design-vue/es/badge/style';

export default {
  name: 'MBadge',
  components: { ABadge: Badge },
  props: {
    count: {
      type: [String, Number]
    },
    dot: {
      type: Boolean,
      default: false
    },
    offset: {
      type: Array
    },
    overflowCount: {
      type: Number,
      default: 99
    },
    showZero: {
      type: Boolean,
      default: false
    },
    status: {
      type: String
    },
    text: {
      type: String
    },
    numberStyle: {
      type: Object
    },
    title: {
      type: String
    }
  }
};
</script>
