<template>
  <a-tooltip
    :destroyTooltipOnHide="true"
    :mouseEnterDelay="mouseEnterDelay"
    :mouseLeaveDelay="mouseLeaveDelay"
    :overlayClassName="overlayClassName"
    :overlayStyle="overlayStyle"
    :placement="placement"
    :trigger="trigger"
    :visible="isOpen"
    @visibleChange="visibleChange"
  >
    <template slot="title">
      <slot>Sample Tooltip</slot>
    </template>
    <slot :hide="hide" :show="show" :toggle="toggle" name="trigger"
      >Tooltip trigger</slot
    >
  </a-tooltip>
</template>

<script>
import Tooltip from 'ant-design-vue/es/tooltip'
import 'ant-design-vue/es/tooltip/style'
import Toggle from '../../mixins/toggle'

export default {
  name: 'MTooltip',
  components: { ATooltip: Tooltip },
  mixins: [Toggle],
  props: {
    trigger: {
      type: String,
      default: 'hover'
    },
    placement: {
      type: String,
      default: 'top'
    },
    mouseEnterDelay: {
      type: Number
    },
    mouseLeaveDelay: {
      type: Number
    },
    overlayClassName: {
      type: String
    },
    overlayStyle: {
      type: Object
    },
    visible: {
      type: Boolean
    }
  }
}
</script>
