<template>
  <a-layout-header>
    <slot/>
  </a-layout-header>
</template>

<script>
import Layout from 'ant-design-vue/es/layout';

export default {
  name: 'MLayoutHeader',
  components: { ALayoutHeader: Layout.Header }
};
</script>
