import * as Components from './components'
import ServiceMap, {
  successToast,
  errorToast,
  loadingToast,
  errorNotification,
  successNotification,
  infoNotification
} from './services'
import './style/index.less'

export * from './components'
export {
  successToast,
  errorToast,
  loadingToast,
  errorNotification,
  successNotification,
  infoNotification
}

export default {
  // register all components
  install(Vue) {
    // Motadata ui component registrations start
    Object.keys(Components).forEach((key) => {
      const component = Components[key]
      if (component.name) {
        Vue.component(component.name, component)
      }
    })
    // register services
    Object.keys(ServiceMap).forEach((key) => {
      Vue.prototype[key] = ServiceMap[key]
    })
  }
}
