<template>
  <a-card-meta>
    <template #title v-if="$scopedSlots.header">
      <slot name="header"/>
    </template>
    <template #avatar v-if="$scopedSlots.avatar || avatar">
      <slot name="avatar">
        <m-avatar :src="avatar"/>
      </slot>
    </template>
    <template #description>
      <slot/>
    </template>
  </a-card-meta>
</template>

<script>
import Meta from 'ant-design-vue/es/card/Meta';
import { MAvatar } from '../';

export default {
  name: 'MCardMeta',
  components: { ACardMeta: Meta, MAvatar },
  props: {
    avatar: {
      type: String
    }
  }
};
</script>
