<template>
  <a-tag
    :class="{
      'neutral-ligher': variant === 'neutral-lighter',
      'dropdown-trigger-tag': variant === 'dropdown-trigger',
      default: variant === 'default',
      rounded: rounded,
    }"
    :closable="false"
    :color="tagColor"
    :visible="visible"
    v-bind="$attrs"
    v-on="listeners"
  >
    <slot />
    <m-confirm-btn
      @confirm="close"
      v-if="confirmable && closable"
      :cancelText="cancelText"
      :okText="okText"
    >
      <template #title>
        <slot name="confirm-title"></slot>
      </template>
      <m-icon name="times" size="xs" />
    </m-confirm-btn>
    <a @click="close" style="color: inherit;" v-if="!confirmable && closable">
      <m-icon name="times" size="xs" />
    </a>
  </a-tag>
</template>

<script>
import Tag from 'ant-design-vue/es/tag'
import 'ant-design-vue/es/tag/style'
import ColorPalette from '../../style/colorPalette'
import { MIcon, MConfirmBtn } from '../'

export default {
  name: 'MTag',
  components: { MIcon, MConfirmBtn, ATag: Tag },
  props: {
    closable: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
    },
    rounded: {
      type: Boolean,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
    },
    confirmable: {
      type: Boolean,
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    okText: {
      type: String,
      default: 'Yes',
    },
  },
  computed: {
    tagColor() {
      if (this.color) {
        return this.color
      }
      const variant = this.variant
      if (variant) {
        if (ColorPalette[variant]) {
          return ColorPalette[variant]
        }
      }
      return undefined
    },
    listeners() {
      const { close, ...listeners } = this.$listeners
      return listeners
    },
  },
  methods: {
    close(e) {
      if (e) {
        e.stopImmediatePropagation()
        e.stopPropagation()
      }
      this.$emit('close')
    },
  },
}
</script>
