<template>
  <a-pagination
    :key="renderCount"
    :current="currentPage"
    :defaultCurrent="defaultValue"
    :defaultPageSize="defaultPageSize"
    :hideOnSinglePage="hideOnSinglePage"
    :pageSize="currentPageSize"
    :pageSizeOptions="pageSizeOptions"
    :showQuickJumper="showQuickJumper"
    :showSizeChanger="showSizeChanger"
    :showTotal="showTotal"
    :simple="simple"
    :size="size"
    :total="total"
    @change="handlePageChange"
    @showSizeChange="handlePageSizeChange"
  >
    <template slot="buildOptionText" slot-scope="props">
      <slot :value="props.value" />
    </template>
  </a-pagination>
</template>

<script>
import Pagination from 'ant-design-vue/es/pagination'
import 'ant-design-vue/es/pagination/style'

export default {
  name: 'MPagination',
  components: { APagination: Pagination },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      renderCount: 1,
    }
  },
  props: {
    value: {
      type: Number,
    },
    defaultValue: {
      type: Number,
      default: 1,
    },
    defaultPageSize: {
      type: Number,
      default: 10,
    },
    hideOnSinglePage: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
    },
    pageSizeOptions: {
      type: Array,
      default: () => ['10', '25', '50'],
    },
    showQuickJumper: {
      type: Boolean,
    },
    showSizeChanger: {
      type: Boolean,
      default: true,
    },
    simple: {
      type: Boolean,
    },
    size: {
      type: String,
    },
    total: {
      type: Number,
      default: 0,
    },
    showTotal: {
      type: Function,
    },
  },
  computed: {
    currentPage() {
      return this.value || this.defaultValue
    },
    currentPageSize() {
      return this.pageSize || this.defaultPageSize
    },
  },
  methods: {
    handlePageChange(page, pageSize) {
      this.$emit('change', page, pageSize)
    },
    handlePageSizeChange(current, pageSize) {
      this.$emit('update:page-size', pageSize)
      this.renderCount++
    },
  },
}
</script>
