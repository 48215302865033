<template>
  <a-layout>
    <slot/>
  </a-layout>
</template>

<script>
import Layout from 'ant-design-vue/es/layout';
import 'ant-design-vue/es/layout/style';

export default {
  name: 'MLayout',
  components: { ALayout: Layout }
};
</script>
