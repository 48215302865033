<template>
  <a-select-option
    :disabled="disabled"
    :title="title"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </a-select-option>
</template>

<script>
import Select from 'ant-design-vue/es/select'

export default {
  name: 'MSelectOption',
  components: { ASelectOption: Select.Option },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      required: true
    },
    title: {
      type: String
    }
  }
}
</script>
