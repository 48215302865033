<template>
  <a-input-group :compact="compact" :size="size">
    <slot/>
  </a-input-group>
</template>

<script>
import Group from 'ant-design-vue/es/input/Group';

export default {
  name: 'MInputGroup',
  components: { AInputGroup: Group },
  props: {
    compact: {
      type: Boolean
    },
    size: {
      type: String
    }
  }
};
</script>
