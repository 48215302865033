<template>
  <a-layout-footer>
    <slot/>
  </a-layout-footer>
</template>

<script>
import Layout from 'ant-design-vue/es/layout';

export default {
  name: 'MLayoutFooter',
  components: { ALayoutFooter: Layout.Footer }
};
</script>
