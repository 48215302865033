<template>
  <component
    :defaultValue="defaultValue"
    :is="inputComponent"
    :type="type"
    @change="handleChange"
    @input="handleInput"
    @pressEnter="handleEnter"
    @search="handleSearch"
    ref="inputBox"
    v-bind="attrs"
    v-on="listeners"
  >
    <template #addonAfter v-if="$scopedSlots.addonAfter">
      <slot name="addonAfter" />
    </template>
    <template #addonBefore v-if="$scopedSlots.addonBefore">
      <slot name="addonBefore" />
    </template>
    <template #prefix v-if="$scopedSlots.prefix">
      <slot name="prefix" />
    </template>
    <template #suffix v-if="$scopedSlots.suffix">
      <slot name="suffix" />
    </template>
    <template #enterButton v-if="$scopedSlots.enterButton">
      <slot name="enterButton">
        <m-icon name="search" />
      </slot>
    </template>
    <template #[name]="slotData" v-for="(_, name) in $scopedSlots">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import Input from 'ant-design-vue/es/input'
import 'ant-design-vue/es/input/style'
import { MIcon } from '../'
import InputSearch from './InputSearch.vue'
import InputNumber from './InputNumber.vue'

export default {
  name: 'MInput',
  components: {
    AInput: Input,
    ATextarea: Input.TextArea,
    MInputSearch: InputSearch,
    MInputNumber: InputNumber,
    MIcon
  },
  model: {
    event: 'update'
  },
  props: {
    value: {},
    type: {
      type: String,
      default: 'text'
    },
    defaultValue: {
      type: String
    }
  },
  methods: {
    handleInput(e) {
      let v = e && e.target ? e.target.value : e
      this.$emit('update', v)
    },
    handleChange(e) {
      // let v = e && e.target ? e.target.value : e
      // this.$emit('change', v)
    },
    handleSearch(value) {
      this.$emit('search', value)
    },
    handleEnter(e) {
      if (this.type === 'search') {
        e.preventDefault()
        e.stopPropagation()
        this.handleSearch(e.target.value)
      }
      this.$emit('enterKey', e)
    },
    focus() {
      if (this.$refs.inputBox && this.$refs.inputBox.focus) {
        this.$refs.inputBox.focus()
      }
    }
  },
  computed: {
    inputComponent() {
      if (this.type === 'search') {
        return 'm-input-search'
      }
      if (this.type === 'textarea') {
        return 'a-textarea'
      }
      if (this.type === 'number') {
        return 'm-input-number'
      }
      if (this.type === 'datetime') {
        return 'm-date-picker'
      }
      return 'a-input'
    },
    listeners() {
      const {
        input,
        change,
        pressEnter,
        search,
        ...listeners
      } = this.$listeners
      return listeners
    },
    attrs() {
      const attrs = { ...this.$attrs }
      if (this.innerValue !== undefined) {
        attrs.value = this.innerValue
      }
      return attrs
    },
    innerValue() {
      return this.value
    }
  }
}
</script>
