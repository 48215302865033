<template>
  <a-radio-group
    :buttonStyle="buttonStyle"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :name="name"
    :size="size"
    v-model="innerValue"
  >
    <slot>
      <template v-if="options">
        <component
          :is="asButton ? 'a-radio-button' : 'a-radio'"
          :key="String(option.key || option.value)"
          :label="option.text || option.label || option.title"
          :value="option.value"
          :disabled="option.disabled"
          v-for="option in options"
        >
          <slot name="option" :option="option">
            {{ option.text || option.label || option.title }}
          </slot>
        </component>
      </template>
    </slot>
  </a-radio-group>
</template>

<script>
import Radio from 'ant-design-vue/es/radio'

export default {
  name: 'MRadioGroup',
  components: {
    ARadioGroup: Radio.Group,
    ARadio: Radio,
    ARadioButton: Radio.Button
  },
  model: {
    event: 'change'
  },
  props: {
    asButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultValue: {},
    options: {
      type: Array
    },
    name: {
      type: String
    },
    size: {
      type: String,
      default: 'default'
    },
    buttonStyle: {
      type: String,
      default: 'solid'
    },
    value: {
      defaultValue() {
        return this.defaultValue
      }
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  }
}
</script>
