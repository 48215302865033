<template>
  <a-input-number
    :autoFocus="autoFocus"
    :decimalSeparator="decimalSeparator"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :max="max"
    :min="min"
    :precision="precision"
    :readOnly="readOnly"
    :size="size"
    :step="step"
    @change="handleChange"
    @blur="$emit('blur', $event)"
    v-bind="attrs"
    v-on="listeners"
  />
</template>

<script>
import InputNumber from 'ant-design-vue/es/input-number'
import 'ant-design-vue/es/input-number/style'

export default {
  name: 'MInputNumber',
  components: { AInputNumber: InputNumber },
  model: { event: 'update' },
  props: {
    value: [Number, String],
    autoFocus: Boolean,
    readOnly: Boolean,
    defaultValue: {
      type: Number,
      default: 0
    },
    disabled: Boolean,
    formatter: Function,
    max: {
      type: Number,
      default() {
        return undefined
      }
    },
    min: {
      type: Number,
      default() {
        return undefined
      }
    },
    parser: {
      type: Function,
      default(s) {
        return s
      }
    },
    precision: {
      type: Number,
      default: 2
    },
    decimalSeparator: {
      type: String,
      default: '.'
    },
    size: {
      type: String
    },
    step: {
      type: [Number, String],
      default: 1
    }
  },
  methods: {
    handleChange(v) {
      this.$emit('update', v || 0)
      this.$emit('change', v || 0)
    }
  },
  computed: {
    attrs() {
      const attrs = { ...this.$attrs }
      if (this.innerValue !== undefined) {
        attrs.value = this.innerValue
      }
      return attrs
    },
    listeners() {
      const { change, input, update, blur, ...listeners } = this.$listeners
      return listeners
    },
    innerValue() {
      return this.value
    }
  }
}
</script>
