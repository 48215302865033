import message from 'ant-design-vue/es/message';
import 'ant-design-vue/es/message/style';

message.config({
  top: '10px',
  duration: 3,
  maxCount: 3
});

const ToastMaker = (type = 'info') => {
  if (!message[type]) {
    throw new Error(`message of ${type} is not defined`);
  }
  let defaultDuration = 3;
  if (type === 'loading') {
    defaultDuration = 0;
  }
  return (content = '', duration = defaultDuration, onClose) =>
    message[type](content, duration, onClose);
};

export default ToastMaker;
