import Toggle from '../../mixins/toggle';

export default {
  props: {
    allowClear: {
      type: Boolean,
      default: true
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean
    },
    disabledDate: {
      type: Function
    },
    placeholder: {
      type: [String, Array]
    },
    popupStyle: {
      type: Object
    },
    dropdownClassName: {
      type: String
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  mixins: [Toggle]
}
