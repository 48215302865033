<template>
  <a-layout-content>
    <slot/>
  </a-layout-content>
</template>

<script>
import Layout from 'ant-design-vue/es/layout';

export default {
  name: 'MLayoutContent',
  components: { ALayoutContent: Layout.Content }
};
</script>
