<template>
  <a-divider
    :class="{
      'orientation-end': orientation === 'end',
      'orientation-start': orientation === 'start',
      'dark-variant': darkVariant,
    }"
    :dashed="dashed"
    :orientation="
      orientation === 'end'
        ? 'right'
        : orientation === 'start'
        ? 'left'
        : orientation
    "
    :type="type"
  >
    <slot />
  </a-divider>
</template>

<script>
import Divider from 'ant-design-vue/es/divider'
import 'ant-design-vue/es/divider/style'

export default {
  name: 'MDivider',
  components: { ADivider: Divider },
  props: {
    darkVariant: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'horizontal'
    },
    dashed: {
      type: Boolean,
      default: false
    },
    orientation: {
      type: String
    }
  }
}
</script>

<style>
.orientation-end::before {
  width: 100% !important;
}
.orientation-end::after {
  width: 0% !important;
}
.orientation-start::before {
  width: 0% !important;
}
.orientation-start::after {
  width: 100% !important;
}
</style>
