<template>
  <AAffix
    :offsetBottom="offsetBottom"
    :offsetTop="offsetTop"
    :target="getContainer"
    @change="change"
  >
    <slot />
  </AAffix>
</template>

<script>
import Affix from 'ant-design-vue/es/affix'
import 'ant-design-vue/es/affix/style'
export default {
  name: 'MAffix',
  components: { AAffix: Affix },
  props: {
    offsetBottom: {
      type: Number
    },
    offsetTop: {
      type: Number
    },
    getAffixContainer: {
      type: Function
    }
  },
  methods: {
    getContainer() {
      const element = this.getAffixContainer
        ? this.getAffixContainer()
        : this.$el.closest('.__panel')
      if (element) {
        return element
      }
      return document.body
    },
    change(affixed) {
      this.$emit('change', affixed)
    }
  }
}
</script>
