<template>
  <a-time-picker
    :allowClear="allowClear"
    :autoFocus="autoFocus"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :format="format"
    :open="isOpen"
    :placeholder="placeholder"
    :size="size"
    :disabledHours="disabledHours"
    :disabledMinutes="disabledMinutes"
    @change="handleChange"
    :use12Hours="use12Hours"
    :value="currentValue"
    @openChange="visibleChange"
    style="width: 100%"
  >
    <template #suffixIcon>
      <m-icon name="clock" />
    </template>
  </a-time-picker>
</template>

<script>
import TimePicker from 'ant-design-vue/es/time-picker'
import 'ant-design-vue/es/time-picker/style'
import Moment from 'moment'
import { MIcon } from '../'
import BaseMixin from './BaseMixin'

export default {
  name: 'MTimePicker',
  mixins: [BaseMixin],
  components: {
    MIcon,
    ATimePicker: TimePicker,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    allowClear: { type: Boolean, default: true },
    autoFocus: { type: Boolean },
    value: {
      type: [String, Number, Object],
    },
    defaultValue: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
    format: {
      type: String,
      default: 'hh:mm A',
    },
    use12Hours: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
    },
    placeholder: {
      default: 'Select...',
    },
    disabledHours: {
      type: Function,
      default: undefined,
    },
    disabledMinutes: {
      type: Function,
      default: undefined,
    },
  },
  computed: {
    currentValue() {
      if (typeof this.value === 'string') {
        return Moment(this.value, this.format)
      } else if (typeof this.value === 'number') {
        return Moment.unix(this.value / 1000)
      }
      return undefined
    },
  },
  methods: {
    handleChange(value, timeString) {
      this.$emit('change', value ? value.format(this.format) : undefined, value)
    },
  },
}
</script>
