<script>
import Collapse from 'ant-design-vue/es/collapse'

export default {
  name: 'MCollapsePanel',
  extends: Collapse.Panel,
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    forceRender: {
      type: Boolean,
      default: false
    },
    header: {
      type: String
    },
    showArrow: {
      type: Boolean,
      default: true
    }
  }
  // computed: {
  //   panelKey() {
  //     return this.$vnode.key;
  //   }
  // }
}
</script>
